import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  IListColumn,
  ISortColumn,
  PaginationResponse,
  TransactionHistoryParams,
} from 'src/@types/search-params';
import { getTransactionList } from 'src/api/localpay';
import TableContent from 'src/components/CTable';
import { useAppDispatch } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setModalState } from 'src/redux/slice/modal.store';
import { TransactionHistoryType } from 'src/shared/enum/localpay';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { TransactionData } from 'src/shared/interface/register_and_cancel';
import { exportExcel, formatDate, formatMoney, parseQueryString } from 'src/utils';

function TransactionListPage() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [data, setData] = useState<PaginationResponse<TransactionData[]>>();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();

  const userStore = localStorage.getItem('merchantInfo');
  let merchantInfo: any;
  if (userStore !== null) {
    try {
      merchantInfo = JSON.parse(userStore);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchTransactionList = async (searchParamsOption: TransactionHistoryParams) => {
    const res: any = await getTransactionList(searchParamsOption, merchantInfo.merchantId);

    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    const pagination = parseQueryString(location.search);
    fetchTransactionList({
      ...pagination,
      pageIndex: pagination.pageIndex ?? 1,
      pageSize: pagination.pageSize ?? 10,
    });
  }, [location.search]);

  function handleExport() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_giao_dich_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const pagination = parseQueryString(location.search);
    delete pagination.pageIndex;
    delete pagination.pageSize;

    const params = queryString.stringify({ ...pagination, distributorId: merchantInfo.merchantId });
    const url = process.env.REACT_APP_API_URL + `/api/Provider/export-excel/transaction?${params}`;
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  }

  const renderType = (data: TransactionHistory) => {
    switch (data.type) {
      case TransactionHistoryType.Topup:
        return <span>Nạp tiền ví LCS App</span>;
      case TransactionHistoryType.Recharge:
        return <span>Nạp tiền ví LCS App</span>;
      case TransactionHistoryType.RechargeAsimPortal:
        return <span>Nạp tiền ví Asim Portal</span>;
      case TransactionHistoryType.ChangeSim:
        return <span>Đổi SIM</span>;
      case TransactionHistoryType.RegisterPackage:
        return <span>Gán gói</span>;
      case TransactionHistoryType.CancelPackage:
        return <span>Huỷ gói</span>;
      case TransactionHistoryType.InitiatePackage:
        return <span>Đăng ký gói</span>;
      case TransactionHistoryType.EnterpriseTopup:
        return <span>Nạp tiền</span>;
      default:
        return <span>Nạp tiền ví</span>;
    }
  };

  function handleFormSearch(value: any) {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : value.dateFrom ?? undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : value.dateTo ?? undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });
    setSearchParams({ pageIndex: 1, pageSize: 10, ...values });
  }

  const handleDetail = (record: any) => {
    console.log(record);
    dispatch(setGlobalState({ order: record }));
    if (record.type === TransactionHistoryType.EnterpriseTopup) {
      dispatch(
        setModalState({
          open: true,
          content: 'top-up-package-info',
          selectedHistory: record,
          width: '70%',
        }),
      );
    } else if (record.type === TransactionHistoryType.InitiatePackage) {
      dispatch(
        setModalState({
          open: true,
          content: 'register-package-info',
          selectedHistory: record,
          width: '70%',
        }),
      );
    } else {
      dispatch(
        setModalState({
          open: true,
          content: 'top-up',
          selectedHistory: record,
          width: '70%',
        }),
      );
    }
  };
  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Mã giao dịch',
      fieldName: 'transactionId',
      isHideSort: false,
      renderSpecialCard: (record) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => {
            handleDetail(record);
          }}
        >
          {record.transactionId ?? ''}
        </button>
      ),
    },
    {
      name: 'Loại',
      fieldName: 'type',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{renderType(record)}</span>;
      },
    },
    {
      name: 'Mã đơn hàng',
      fieldName: 'paymentId',
      isHideSort: true,
    },
    {
      name: 'PTTT',
      fieldName: 'paymentMethod',
      isHideSort: true,
    },
    {
      name: 'Thành tiền',
      fieldName: 'amount',
      isHideSort: true,
      renderSpecialCard: (record: TransactionData) => {
        return <span>{record.amount ? formatMoney(record.amount, 'đ') : 0}</span>;
      },
    },
    {
      name: 'Trạng thái',
      fieldName: 'status',
      isHideSort: false,
      renderSpecialCard: (record: TransactionData) => {
        return (
          <span>{record.status ? (record.status === 'SUCCESS' ? 'Success' : 'Fail') : ''}</span>
        );
      },
    },
    {
      name: 'Người tạo',
      fieldName: 'createdBy',
      isHideSort: false,
    },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record: TransactionData) => {
        return <span>{record.createdAt ? formatDate(record.createdAt) : ''}</span>;
      },
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (record) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => {
            handleDetail(record);
          }}
        >
          Xem chi tiết
        </button>
      ),
      isHideSort: true,
    },
  ];

  return (
    <Fragment>
      <Form onFinish={handleFormSearch} layout='inline'>
        <Row className='gap-4'>
          <Form.Item name='code'>
            <Input prefix={<SearchOutlined />} placeholder='Mã giao dịch, mã đơn hàng' allowClear />
          </Form.Item>
          <Form.Item name='type' label='Loại đơn hàng'>
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              defaultValue={''}
            >
              <Select.Option value=''>Tất cả</Select.Option>
              <Select.Option value={TransactionHistoryType.EnterpriseTopup}>Nạp tiền</Select.Option>
              <Select.Option value={TransactionHistoryType.InitiatePackage}>
                Đăng ký gói
              </Select.Option>
              <Select.Option value={TransactionHistoryType.RegisterPackage}>Gán gói</Select.Option>
              <Select.Option value={TransactionHistoryType.ChangeSim}>Đổi SIM</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='status' label='Trạng thái'>
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              defaultValue={''}
            >
              <Select.Option value=''>Tất cả</Select.Option>
              <Select.Option value='SUCCESS'>Success</Select.Option>
              <Select.Option value='FAIL'>Fail</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='' label='Người tạo'>
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              defaultValue={merchantInfo ? merchantInfo.merchantName : ''}
            >
              <Select.Option value='merchantName'>
                {merchantInfo ? merchantInfo.merchantName : ''}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='dateRange' label='Ngày tạo'>
            <DatePicker.RangePicker
              disabledDate={(current) => current && current >= moment().endOf('day')}
              placeholder={['từ', 'đến']}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        </Row>

        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>

      <TableContent
        bordered
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: 'Không tìm thấy kết quả' }}
        dataSource={data?.results ?? []}
        columns={columns}
        totalRecord={data?.rowCount ?? 0}
        sortColumn={sortColumn}
        onSort={(sort: ISortColumn) => {
          const pagination = parseQueryString(location.search);
          fetchTransactionList({
            ...pagination,
            pageIndex: pagination.pageIndex ?? 1,
            pageSize: pagination.pageSize ?? 10,
            sortField: sort.fieldName,
            asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? false : undefined,
          });
        }}
      />
    </Fragment>
  );
}

export default TransactionListPage;
