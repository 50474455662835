import { RequestPackageType } from 'src/shared/enum/register_cancel';

export const requestTypeToString = (type: string) => {
  switch (type) {
    case RequestPackageType.CANCEL:
      return 'Huỷ gói';
    case RequestPackageType.REGISTER:
      return 'Đăng ký gói';
    case RequestPackageType.TOPUP:
      return 'Nạp tiền';
  }
};
