import { PaginationResponse, TransactionHistoryParams } from 'src/@types/search-params';
import {
  ImportSim,
  InputChangeSim,
  OrderSim,
  PaymentInput,
} from 'src/shared/interface/changeSim.interface';
import { ICheckImportExcelParamsRequest } from 'src/shared/interface/package_assigned';
import request from '../request';
export const getOrderSims = async (searchParams: TransactionHistoryParams) =>
  //   searchParams: TransactionHistoryParams,
  //   distributorId: string,
  {
    const res = await request<PaginationResponse<OrderSim>>(
      'get',
      '/api/Provider/physical-sim/get-all',
      { ...searchParams },
    );

    if (res.isSucceeded) {
      return res.data;
    }
  };
export const validateSim = async (data: InputChangeSim) => {
  const res = await request<ImportSim>('post', '/api/Provider/physical-sim', data);

  if (res.isSucceeded) {
    return res.data;
  }
};
export const checkImportExcelSerial = async (params: ICheckImportExcelParamsRequest) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });
  const url = '/api/Provider/physical-sim/import';
  const res = await request<ImportSim>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const createOrderSim = async (data: any) => {
  try {
    const res = await request<ImportSim>('post', '/api/Provider/physical-sim/create-order', data);
    if (res.isSucceeded) {
      return res.data;
    }
  } catch (error) {
    return error;
  }
};
export const getOrderDetail = async (id: string) => {
  const res = await request<OrderSim>('get', '/api/Provider/physical-sim/get-detail/' + id);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const doPayment = async (data: PaymentInput) => {
  const res = await request<OrderSim>('post', '/api/Provider/payment-localpay', data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const doDeleOrder = async (id: string) => {
  const res = await request<OrderSim>('post', '/api/Provider/delete-order/?orderId=' + id);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const doCancleOrder = async (id: string) => {
  const res = await request('post', '/api/Provider/cancel-order/' + id);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const doChangeSim = async (data: any) => {
  const res = await request('post', '/api/Provider/physical-sim/change-sim', data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateChangeSim = async (data: any) => {
  const res = await request('post', '/api/Provider/physical-sim/update-order', data);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkEsimExpired = async (orderId: string) => {
  const res = await request('get', '/api/Provider/check-esim-expired/' + orderId);
  if (res?.isSucceeded) {
    return res?.data;
  }
};
export const retryOrderChangeSim = async (data: { orderId: string; updatedBy: string }) => {
  const res = await request('post', '/api/Provider/physical-sim/retry-order', data);
  if (res?.isSucceeded) {
    return res?.data;
  }
};
