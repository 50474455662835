import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOverFlowOrderDetailResponse } from 'src/shared/interface/package_assigned';

export interface PackageState {
  loading: boolean;
  dataSuccessRegister: IOverFlowOrderDetailResponse[];
  dataFailedRegister: IOverFlowOrderDetailResponse[];
  dataSuccessCancel: IOverFlowOrderDetailResponse[];
  dataFailedCancel: IOverFlowOrderDetailResponse[];
  dataSuccessTopup: IOverFlowOrderDetailResponse[];
  dataFailedTopup: IOverFlowOrderDetailResponse[];
}
const initialState: PackageState = {
  loading: false,
  dataSuccessCancel: [],
  dataSuccessRegister: [],
  dataFailedRegister: [],
  dataFailedCancel: [],
  dataSuccessTopup: [],
  dataFailedTopup: [],
};
export const registerAndCancelPackageSlice = createSlice({
  name: 'registerAndCancelPackage',
  initialState,
  reducers: {
    setRegisterPackageState(state, action: PayloadAction<Partial<PackageState>>) {
      Object.assign(state, action.payload);
    },
    setCancelPackageState(state, action: PayloadAction<Partial<PackageState>>) {
      Object.assign(state, action.payload);
    },
   
  },
});

export const { actions: registerAndCancelPackageAction, reducer: registerAndCancelPackageReducer } =
  registerAndCancelPackageSlice;
export const { setRegisterPackageState, setCancelPackageState } = registerAndCancelPackageSlice.actions;
