export enum OrderStatusEnum{
  New = 'New',
  Processing = 'Processing',
  Completed = 'Completed',
  Uncompleted = 'Uncompleted',
  Failed = 'Failed',
  Deleted = 'Deleted',
  Paid = 'Paid',
  PAYMENT_FAIL = 'PaymentFail',
  EDIT = 'Edit',
  Cancel = 'Canceled',
  Pending = 'PENDING',
}
