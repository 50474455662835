import {
  BookFilled,
  CopyOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  ProjectOutlined,
  RedoOutlined,
  ScanOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu, MenuProps, Tabs } from 'antd';
import { ItemType, MenuItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';
import { useEffect, useState } from 'react';
import { BiBuildings } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { LiaMoneyCheckAltSolid } from 'react-icons/lia';
import { MdEmojiPeople } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getDetailBusinessProfile } from 'src/api/business_profile';
import Loading from 'src/components/CLoading';
import CModal from 'src/components/CModal';
import { setUserProfileState } from 'src/redux/slice/userProfile';
import { DataEnterprise } from 'src/shared/interface/user_profile';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { reset, setGlobalState } from '../../redux/slice/globalSlice';

const { Header, Sider } = Layout;
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const hideMenus = ['changeSim/createChangeSim'];
const menuItems: MenuProps['items'] = [
  {
    key: `dashboard`,
    label: `Dashboard`,
    icon: <ProjectOutlined />,
  },
  {
    key: `settings`,
    label: `Thiết lập hệ thống`,
    icon: <FiSettings />,
  },
  {
    key: `permissions`,
    label: `User và phân quyền`,
    icon: <TeamOutlined />,
  },

  // hidden for pharse 1
  {
    key: 'profile',
    label: `Quản lí thông tin doanh nghiệp`,
    icon: <BiBuildings />,
    // children: [
    //   {
    //     key: `profile/list`,
    //     label: `Danh sách doanh nghiệp`,
    //   },
    // ],
  },
  {
    key: 'user-profile',
    label: `Quản lý hồ sơ người sử dụng`,
    icon: <MdEmojiPeople />,
    children: [
      {
        key: `user-profile/list`,
        label: `Danh sách hồ sơ người sử dụng`,
      },
      {
        key: `user-profile/batch-create`,
        label: `Tạo mới hồ sơ người sử dụng theo lô`,
      },
    ],
  },
  {
    key: `localpay`,
    label: `Quản lý Localpay`,
  },
  {
    key: `changeSim`,
    label: `Đơn hàng Đổi sim`,
    icon: <RedoOutlined />,
  },
  {
    key: `changeSim/createChangeSim`,
    label: `Tạo mới đơn hàng đổi sim`,
    icon: <RedoOutlined />,
  },
  {
    key: 'connection',
    label: `Đấu nối`,
    children: [
      {
        key: `connection/single`,
        label: `Đấu nối đơn lẻ`,
      },
      {
        key: `connection/batch`,
        label: `Đấu nối theo lô`,
      },

      // hidden for pharse 1
      {
        key: `connection/history`,
        label: `Lịch sử đấu nối`,
      },
    ],
  },
  {
    key: `order-assigned-package`,
    label: `Đơn hàng gán gói`,
  },
  {
    key: `active-sim`,
    label: `Quản lý danh sách TB`,
    icon: <FileDoneOutlined />,
    children: [
      {
        key: `active-sim/list`,
        label: `Danh sách TB`,
      },
    ],
  },
  {
    key: `esim-management`,
    label: `Quản lý eSIM`,
    icon: <ScanOutlined />,
  },
  // {
  //   key: `register-and-cancel-package`,
  //   label: `Đăng ký/Huỷ gói`,
  //   icon: <CopyOutlined />,
  //   children: [
  //     {
  //       key: `register-and-cancel-package/register/list`,
  //       label: `Đơn hàng Đăng ký gói`,
  //     },
  //     {
  //       key: `register-and-cancel-package/cancel/list`,
  //       label: `Đơn hàng Huỷ gói`,
  //     },
  //     {
  //       key: `register-and-cancel-package/register/history`,
  //       label: `Lịch sử Đăng ký/Huỷ gói`,
  //     },
  //   ],
  // },
  {
    key: `topup`,
    label: `Nạp tiền`,
    icon: <LiaMoneyCheckAltSolid />,
    children: [
      {
        key: `topup/list`,
        label: `Đơn hàng nạp tiền`,
      },
      {
        key: `topup/history`,
        label: `Lịch sử nạp tiền`,
      },
    ],
  },
  // {
  //   key: `transaction`,
  //   label: `Danh sách Giao dịch`,
  //   icon: <BookFilled />,
  // },
];

const ManagementPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openTabs, activeKeyMenu } = useAppSelector((state) => state.global);
  const [activeKey, setActiveKey] = useState(location.pathname.split('/')[2]);
  const [items, setItems] = useState(openTabs);

  const findMenuByKey = (menuItem: MenuProps['items'], key: string): MenuItemType | undefined => {
    const _menuItem = menuItem?.find((item: ItemType) => {
      const subMenuItem = item as SubMenuType;
      if (subMenuItem.key === key) {
        return true;
      }

      if (subMenuItem.children) {
        return !!findMenuByKey(subMenuItem.children as MenuProps['items'], key);
      }
      return false;
    });

    return _menuItem as MenuItemType;
  };

  const add = (newTabKey: string) => {
    const newActiveKey = newTabKey?.split('/')[0];
    const _itemFound = items?.find((x) => x.key === newTabKey);
    let _menuItem = menuItems?.find((x) => x?.key === newActiveKey) as SubMenuType;

    if (_menuItem?.children) {
      _menuItem = _menuItem.children?.find((item) => item?.key === newTabKey) as SubMenuType;
    }
    if (!_itemFound && _menuItem) {
      setItems([
        ...items,
        {
          label: _menuItem.label?.toString() ?? '',
          children: '',
          key: newTabKey,
          closable: newTabKey !== 'dashboard',
        },
      ]);
      dispatch(
        setGlobalState({
          openTabs: [
            ...items,
            {
              label: _menuItem.label?.toString() ?? '',
              children: '',
              key: newTabKey,
              closable: newTabKey !== 'dashboard',
            },
          ],
        }),
      );
    }
    dispatch(
      setGlobalState({
        activeKeyMenu: newTabKey,
      }),
    );
    setActiveKey(newTabKey);
    navigate(`${newTabKey}`);
  };

  const remove = (targetKey: TargetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey);
    const newPanes = items.filter((pane) => pane.key !== targetKey);
    if (newPanes?.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes?.length ? targetIndex - 1 : targetIndex];
      dispatch(
        setGlobalState({
          activeKeyMenu: key,
        }),
      );
      setActiveKey(key);
      navigate(`${key}`);
    }
    setItems(newPanes);
    dispatch(
      setGlobalState({
        openTabs: newPanes,
      }),
    );
  };

  const onEdit = (targetKey: TargetKey) => {
    remove(targetKey);
  };

  const onChange = (newActiveKey: string) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: newActiveKey,
      }),
    );
    setActiveKey(newActiveKey);
    navigate(`${newActiveKey}`);
  };
  const handleLogout = () => {
    // localStorage.removeItem('token');
    dispatch(reset());
    localStorage.clear();
    navigate('/login');
  };

  const menu = (
    <Menu
      className='rounded-xl'
      items={[
        {
          key: '1',
          className: 'rounded-xl',
          label: (
            <button
              className='bg-transparent border-transparent cursor-pointer'
              onClick={handleLogout}
            >
              Đăng xuất
            </button>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    // eslint-disable-next-line no-debugger
    setItems(openTabs);
    setActiveKey(activeKeyMenu);
    navigate('/management/' + activeKeyMenu);
  }, [openTabs, activeKeyMenu]);

  useEffect(() => {
    add(activeKeyMenu);
    fetchDataEnterprise();
  }, []);

  const fetchDataEnterprise = async () => {
    try {
      const res: DataEnterprise | undefined = await getDetailBusinessProfile();
      if (res) {
        dispatch(setUserProfileState({ dataEnterprise: res }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClearTabs = () => {
    dispatch(
      setGlobalState({
        activeKeyMenu: '',
        openTabs: [],
      }),
    );
  };

  return (
    <Layout className='h-screen'>
      <Header className='flex justify-between'>
        <div className='logo' />
        <div>
          {' '}
          <Dropdown trigger={['click']} overlay={menu}>
            <Avatar
              className='cursor-pointer'
              style={{ backgroundColor: '#e2e9f3', verticalAlign: 'middle', color: 'black' }}
              size='large'
            >
              U
            </Avatar>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider className='mt-12' width={210}>
          <Menu
            selectedKeys={[activeKeyMenu]}
            defaultSelectedKeys={[activeKeyMenu]}
            mode='inline'
            style={{ height: '100%', borderRight: 0, overflowY: 'auto' }}
            items={menuItems.filter((menu: any) => !hideMenus.includes(menu?.key))}
            onClick={(e) => {
              console.log(e.key);
              add(e.key);
              navigate(`${e.key}`);
            }}
          />
        </Sider>
        <Layout style={{ padding: '0 12px 24px' }}>
          <Loading />
          <Tabs
            hideAdd
            type='editable-card'
            onChange={onChange}
            onEdit={onEdit}
            activeKey={activeKey}
            items={items}
            className={'mt-2'}
            tabBarExtraContent={
              <div className={`clear-tabs ${!openTabs?.length ? 'invisible' : ''}`}>
                <Button
                  className={`mt-1`}
                  danger
                  type={'link'}
                  size={'small'}
                  icon={<DeleteOutlined />}
                  onClick={onClearTabs}
                />
              </div>
            }
          />
          <div
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              height: '90%',
              background: 'white',
              overflow: 'auto',
              borderRadius: 12,
            }}
          >
            <Outlet />
          </div>
        </Layout>
      </Layout>
      <CModal />
    </Layout>
  );
};

export default ManagementPage;
