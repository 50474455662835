import { Modal } from 'antd';
import ChangeSimInfoModal from 'src/pages/management/changeSim/ChangeSimInfo';
import BillingInfoModal from 'src/pages/management/package_assigned/modals/BillingInfo';
import RegisterBillingInfoModal from 'src/pages/management/register_and_cancel_package/modals/RegisterBillingInfo';
import TopupPackageBillingInfoModal from 'src/pages/management/topup/modals/TopupPackageBillingInfo';
import TransactionInfoModal from 'src/pages/management/transaction/modals/TransactionInfo';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { resetModalState } from 'src/redux/slice/modal.store';
import ModalTopUp from './modals/ModalTopUp';
import RegisterBillingLocalModal from './modals/ModalTopUp/ModalRegisterPackage';
import TopupPackageBillingLocalPayModal from './modals/ModalTopUp/ModalTopupPackage';

const CModal = () => {
  const { open, content, className, selectedHistory, selectedChangeSim, pageAccess, ...props } =
    useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const renderContent = () => {
    switch (content) {
      case 'top-up':
        return <ModalTopUp selectedHistory={selectedHistory!} accessPage={pageAccess} />;
      case 'billing-info':
        return <BillingInfoModal />;
      case 'transaction-info':
        return <TransactionInfoModal />;
      case 'change-sim-info':
        return <ChangeSimInfoModal selectedChangeSim={selectedChangeSim!} />;
      case 'register-package-info':
        return <RegisterBillingInfoModal selectedHistory={selectedHistory} />;
      case 'register-package-info-local-pay':
        return <RegisterBillingLocalModal selectedHistory={selectedHistory} />;
      case 'top-up-package-info':
        return <TopupPackageBillingInfoModal selectedHistory={selectedHistory} />;
      case 'top-up-package-info-local-pay':
        return <TopupPackageBillingLocalPayModal selectedHistory={selectedHistory} />;
      default:
        return <></>;
    }
  };
  return (
    <Modal
      {...props}
      destroyOnClose
      open={open}
      maskClosable={false}
      className={`${className ?? ''} custom-modal`}
      onCancel={() => {
        dispatch(resetModalState());
      }}
      footer={false}
      closable={false}
      centered
    >
      {renderContent()}
    </Modal>
  );
};

export default CModal;
