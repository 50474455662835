import { Col, Form, Input, message, ModalProps, Radio, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import { getDetailPackageAssigned } from 'src/api/package_assigned';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppDispatch } from 'src/redux/hook';
import { resetModalState } from 'src/redux/slice/modal.store';
import { TransactionHistoryType } from 'src/shared/enum/localpay';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { IPackageAssigned } from 'src/shared/interface/package_assigned';
import { formatDateTime, formatMoney, formatMoneyNoCurrency, removeMoneyFormat } from 'src/utils';
import { simTypeToText } from 'src/utils/sim.enum';

interface ModalTopUpProps extends ModalProps {
  selectedHistory: TransactionHistory;
  accessPage?: string;
}

interface FormData {
  transactionAmount: string;
  description?: string;
}

const ModalTopUp = ({ selectedHistory, accessPage = '' }: ModalTopUpProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [showMessage, setShowMessage] = useState('');
  const [inputStatus, setInputStatus] = useState<'' | 'error'>('');
  const [loading, setLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState<IPackageAssigned>();
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const renderType = () => {
    switch (selectedHistory.type) {
      case TransactionHistoryType.Topup:
        return <span>Nạp tiền vào ví LCS App</span>;
      case TransactionHistoryType.Recharge:
        return <span>Nạp tiền vào ví LCS App</span>;
      case TransactionHistoryType.RechargeAsimPortal:
        return <span>Nạp tiền vào ví Asim Portal</span>;
      case TransactionHistoryType.LocalRecharge:
        return <span>Nạp tiền vào tài khoản</span>;
      case TransactionHistoryType.Commission:
        return <span>Cộng tiền hoa hồng NPP</span>;
      case TransactionHistoryType.Refund:
        return <span>Hoàn tiền do lỗi gd</span>;
      case TransactionHistoryType.Reward:
        return <span>Trả thưởng CTV</span>;
      case TransactionHistoryType.ChangeSim:
        return (
          <span>
            {accessPage === 'localpay' ? ' Trừ tiền đổi sim' : 'Thanh toán đơn hàng đổi sim'}
          </span>
        );
      case TransactionHistoryType.RegisterPackage:
        return (
          <span>
            {accessPage === 'localpay' ? 'Trừ tiền gán gói' : 'Thanh toán đơn hàng gán gói'}
          </span>
        );
      case TransactionHistoryType.ChangePackage:
        return (
          <span>
            {accessPage === 'localpay' ? 'Trừ tiền đổi gói' : 'Thanh toán đơn hàng đổi gói'}
          </span>
        );
      case TransactionHistoryType.Excess:
        return <span>Trừ tiền do nạp thừa</span>;
      case TransactionHistoryType.Mistake:
        return <span>Trừ tiền do nạp sai tài khoản</span>;
      case TransactionHistoryType.Paid:
        return <span>Trừ tiền chung</span>;
      case TransactionHistoryType.PaidTopup:
        return <span>Trừ tiền Topup</span>;
      case TransactionHistoryType.CancelPackage:
        return <span>Huỷ gói</span>;
      case TransactionHistoryType.InitiatePackage:
        return <span>Trừ tiền nạp tiền Đăng ký gói</span>;
      case TransactionHistoryType.EnterpriseTopup:
        return <span>Trừ tiền nạp tiền điện thoại</span>;
      default:
        return <span>Nạp tiền ví</span>;
    }
  };

  const handleTopUp = async ({ transactionAmount }: FormData) => {
    try {
      setLoading(true);
      const validated = await form.validateFields();
      if (!validated.transactionAmount) {
        setShowMessage('Số tiền nạp tối thiểu là 1 VND');
        setInputStatus('error');
      }
      if (validated.transactionAmount) {
        if (Number(removeMoneyFormat(transactionAmount.toString())) <= 0) {
          message.error('Số tiền nạp không hợp lệ');
          return;
        }
        if (Number(removeMoneyFormat(transactionAmount.toString())) > 999999999) {
          message.error('Số tiền nạp tối đa là 999,999,999 VND');
          return;
        }
        // const topUpRes = await topUp({
        //   distibutorId: selectedId,
        //   transactionAmount: Number(removeMoneyFormat(transactionAmount)),
        //   description,
        // });
        // if (topUpRes.isSucceeded) {
        //   message.success('Nạp tiền thành công');
        //   form.resetFields();
        //   onCancel();
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    const detailRes = await getDetailPackageAssigned(selectedHistory.paymentId);
    if (detailRes) {
      setDetailOrder(detailRes);
    }
  };

  useEffect(() => {
    if (selectedHistory.paymentId) {
      fetchDataDetail();
    }
  }, [selectedHistory]);

  const renderLinkDirectTo = () => {
    switch (selectedHistory.type) {
      case TransactionHistoryType.RegisterPackage:
        return `/management/order-assigned-package/detail?id=${selectedHistory.paymentId}`;
      case TransactionHistoryType.CancelPackage:
        return `/management/register-and-cancel-package/cancel/detail?id=${selectedHistory.paymentId}`;
      case TransactionHistoryType.InitiatePackage:
        return `/management/register-and-cancel-package/register/detail?id=${selectedHistory.paymentId}`;
      default:
        return `changeSim/createChangeSim?id=${selectedHistory.paymentId}`;
    }
  };

  return (
    <>
      <div className='flex justify-end'>
        <button
          onClick={() => dispatch(resetModalState())}
          className=' text-3xl cursor-pointer border-transparent bg-transparent'
        >
          <RxCross2 />
        </button>
      </div>
      <Form
        initialValues={{
          amount: formatMoney(selectedHistory.amount, 'đ'),
          paymentMethod: selectedHistory.paymentMethod,
        }}
        requiredMark={false}
        form={form}
        onFinish={handleTopUp}
        colon={false}
      >
        <BoxWithLabel title='Thông tin giao dịch'>
          <div className='flex justify-start'>
            <Row gutter={[0, 24]} className='w-full text-left'>
              <Col span={14}>
                <Row gutter={[0, 12]} className='w-full'>
                  <Col span={6}>Mã giao dịch</Col>
                  <Col span={16}>{selectedHistory.transactionId}</Col>
                  <Col span={6}>Ngày</Col>
                  <Col span={16}>{formatDateTime(selectedHistory.transactionDate)}</Col>
                  {[
                    TransactionHistoryType.Topup,
                    TransactionHistoryType.Recharge,
                    TransactionHistoryType.RechargeAsimPortal,
                  ].includes(selectedHistory.type as TransactionHistoryType) ||
                  (!selectedHistory.paymentId &&
                    ![
                      TransactionHistoryType.Topup,
                      TransactionHistoryType.Recharge,
                      TransactionHistoryType.RechargeAsimPortal,
                    ].includes(selectedHistory.type as TransactionHistoryType)) ? (
                    <>
                      <Col span={6}>Mã ví</Col>
                      <Col span={16}>{selectedHistory.accountNumber}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={6}>Diễn giải</Col>
                      <Col span={16}>{renderType()}</Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={[0, 12]} className='w-full'>
                  {[
                    TransactionHistoryType.Topup,
                    TransactionHistoryType.Recharge,
                    TransactionHistoryType.RechargeAsimPortal,
                  ].includes(selectedHistory.type as TransactionHistoryType) ||
                  (!selectedHistory.paymentId &&
                    ![
                      TransactionHistoryType.Topup,
                      TransactionHistoryType.Recharge,
                      TransactionHistoryType.RechargeAsimPortal,
                    ].includes(selectedHistory.type as TransactionHistoryType)) ? (
                    <>
                      <Col span={8}>Tình trạng</Col>
                      <Col span={14}>{selectedHistory.status}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>Người thanh toán</Col>
                      <Col span={14}>{selectedHistory.createdBy}</Col>
                    </>
                  )}
                  {[
                    TransactionHistoryType.Topup,
                    TransactionHistoryType.Recharge,
                    TransactionHistoryType.RechargeAsimPortal,
                  ].includes(selectedHistory.type as TransactionHistoryType) ||
                  (!selectedHistory.paymentId &&
                    ![
                      TransactionHistoryType.Topup,
                      TransactionHistoryType.Recharge,
                      TransactionHistoryType.RechargeAsimPortal,
                    ].includes(selectedHistory.type as TransactionHistoryType)) ? (
                    <>
                      {' '}
                      <Col span={8}>Người thanh toán</Col>
                      <Col span={14}>{selectedHistory.createdBy}</Col>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Col span={8}>Đơn hàng tham chiếu</Col>
                      <Col span={14}>
                        {' '}
                        <Link
                          onClick={() => dispatch(resetModalState())}
                          className='hover:underline underline'
                          to={renderLinkDirectTo()}
                        >
                          {selectedHistory.paymentId}
                        </Link>
                      </Col>
                    </>
                  )}
                  {[
                    TransactionHistoryType.Topup,
                    TransactionHistoryType.Recharge,
                    TransactionHistoryType.RechargeAsimPortal,
                  ].includes(selectedHistory.type as TransactionHistoryType) ||
                  (!selectedHistory.paymentId &&
                    ![
                      TransactionHistoryType.Topup,
                      TransactionHistoryType.Recharge,
                      TransactionHistoryType.RechargeAsimPortal,
                    ].includes(selectedHistory.type as TransactionHistoryType)) ? (
                    <>
                      <Col span={8}>Diễn giải</Col>
                      <Col span={14}>{renderType()}</Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>Tình trạng</Col>
                      <Col span={14}>{selectedHistory.status}</Col>
                    </>
                  )}
                </Row>
              </Col>
              {[
                TransactionHistoryType.Topup,
                TransactionHistoryType.Recharge,
                TransactionHistoryType.RechargeAsimPortal,
              ].includes(selectedHistory.type as TransactionHistoryType) ||
              (!selectedHistory.paymentId &&
                ![
                  TransactionHistoryType.Topup,
                  TransactionHistoryType.Recharge,
                  TransactionHistoryType.RechargeAsimPortal,
                ].includes(selectedHistory.type as TransactionHistoryType)) ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      validateStatus={inputStatus}
                      labelCol={{ span: 24 }}
                      help={showMessage ? showMessage : ''}
                      name='amount'
                      label={
                        [
                          TransactionHistoryType.Topup,
                          TransactionHistoryType.Recharge,
                          TransactionHistoryType.RechargeAsimPortal,
                          TransactionHistoryType.LocalRecharge,
                          TransactionHistoryType.Commission,
                          TransactionHistoryType.Refund,
                          TransactionHistoryType.Reward,
                        ].includes(selectedHistory.type as TransactionHistoryType)
                          ? 'Số tiền cần nạp'
                          : 'Số tiền'
                      }
                      className='mb-0'
                    >
                      <Input
                        disabled
                        onBlur={(e) => {
                          let { value } = e.target;
                          if (value.includes(',')) {
                            value = removeMoneyFormat(e.target.value);
                          }
                          if (value !== '') {
                            if (Number(value) > 999999999) {
                              form.setFieldsValue({
                                amount: formatMoneyNoCurrency(999999999),
                              });
                              setShowMessage('Số tiền nạp tối đa là 999,999,999 VND');
                              setInputStatus('error');
                            } else {
                              form.setFieldsValue({
                                amount: formatMoneyNoCurrency(Number(value)),
                              });
                              setShowMessage('');
                              setInputStatus('');
                            }
                          } else {
                            form.setFieldsValue({ amount: '' });
                            setShowMessage('Số tiền nạp tối thiểu là 1 VND');
                            setInputStatus('error');
                          }
                        }}
                        onChange={async (e) => {
                          const { value: inputValue } = e.target;
                          const reg = /^(\d+)$/;

                          if (reg.test(inputValue) || inputValue === '') {
                            form.setFieldsValue({ amount: inputValue });
                          } else {
                            form.setFieldsValue({
                              amount: inputValue.replace(
                                /[a-zA-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\- ]/,
                                '',
                              ),
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                </>
              ) : (
                <Col span={24}>
                  <p className='font-semibold mb-[0.5rem]'>Chi tiết giao dịch:</p>
                  <div className='normal-table'>
                    <table width='100%' className='normal-table'>
                      <thead>
                        <tr>
                          <th>STT</th>
                          <th>Số lượng</th>
                          <th>
                            {selectedHistory.type === TransactionHistoryType.EnterpriseTopup
                              ? 'Mệnh giá'
                              : 'Mã hàng'}
                          </th>
                          <th>Thành tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>{detailOrder?.countSerial ?? 0}</td>
                          <td>
                            {detailOrder?.type === TransactionHistoryType.ChangeSim
                              ? simTypeToText(detailOrder?.simType)
                              : detailOrder?.type === TransactionHistoryType.EnterpriseTopup
                              ? detailOrder.topupName
                                ? detailOrder.topupName + 'đ'
                                : ''
                              : detailOrder?.overFlowOrderDetailResponses
                              ? detailOrder?.overFlowOrderDetailResponses[0].mbfCode
                              : ''}
                          </td>
                          <td>
                            {detailOrder?.toTalAmount
                              ? formatMoney(detailOrder?.toTalAmount, 'đ')
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Tổng cộng</td>
                          <td>
                            {detailOrder?.toTalAmount
                              ? formatMoney(detailOrder?.toTalAmount, 'đ')
                              : '-'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </BoxWithLabel>
        <BoxWithLabel classNames='mt-4' title='Hình thức thanh toán'>
          <Form.Item name='paymentMethod' className='mb-0'>
            <Radio.Group disabled value={selectedHistory.paymentId}>
              {[
                TransactionHistoryType.Topup,
                TransactionHistoryType.Recharge,
                TransactionHistoryType.RechargeAsimPortal,
                TransactionHistoryType.LocalRecharge,
                TransactionHistoryType.Refund,
                TransactionHistoryType.Commission,
                TransactionHistoryType.Reward,
              ].includes(selectedHistory.type as TransactionHistoryType) ? (
                <Space direction='vertical'>
                  <Radio value={1}>Thẻ nội địa</Radio>
                  <Radio value={2}>Thẻ quốc tế</Radio>
                  <Radio value={'VNPay'}>VNPay</Radio>
                  <Radio value={'BSS'}>BSS</Radio>
                </Space>
              ) : (
                <Radio value={'LocalPay'}>Localpay</Radio>
              )}
            </Radio.Group>
          </Form.Item>
          {![
            TransactionHistoryType.Topup,
            TransactionHistoryType.Recharge,
            TransactionHistoryType.RechargeAsimPortal,
            TransactionHistoryType.LocalRecharge,
            TransactionHistoryType.Refund,
            TransactionHistoryType.Commission,
            TransactionHistoryType.Reward,
          ].includes(selectedHistory.type as TransactionHistoryType) && (
            <div className='flex gap-6'>
              <Form.Item label='Localpay ID'>
                <Input
                  value={merchantInfo ? JSON.parse(merchantInfo).payAccountId : '-'}
                  disabled
                />
              </Form.Item>
              <Form.Item label='Tên chủ tài khoản'>
                <Input
                  value={merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}
                  disabled
                />{' '}
              </Form.Item>
            </div>
          )}
        </BoxWithLabel>
        {[
          TransactionHistoryType.Topup,
          TransactionHistoryType.Recharge,
          TransactionHistoryType.RechargeAsimPortal,
        ].includes(selectedHistory.type as TransactionHistoryType) ? (
          <Row className='my-4'>
            <Col span={12}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Người tạo</Col>
                <Col span={16}>{selectedHistory.createdBy}</Col>
              </Row>
            </Col>
            <Col span={12}>
              {' '}
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Ngày tạo</Col>
                <Col span={16}>{formatDateTime(selectedHistory.createdAt)}</Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className='my-4 border-solid border px-4'>
            <Col span={24}>
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Người tạo</Col>
                <Col span={16}>{selectedHistory.createdBy}</Col>
              </Row>
            </Col>
            <Col span={24}>
              {' '}
              <Row gutter={[0, 12]} className='w-full'>
                <Col span={8}>Ngày tạo</Col>
                <Col span={16}>{formatDateTime(selectedHistory.createdAt)}</Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* <Form.Item>
          <Row>
            <Col span={24} className='flex justify-end'>
              <Button loading={loading} htmlType='submit'>
                Xác nhận
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  dispatch(resetModalState());
                }}
              >
                Hủy
              </Button>
            </Col>
          </Row>
        </Form.Item> */}
      </Form>
    </>
  );
};

export default ModalTopUp;
