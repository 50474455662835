import { PaginationResponse } from 'src/@types/search-params';
import {
  CheckMbfCodePackageAssigned,
  ICheckImportExcelParamsRequest,
  ICheckInputListSerial,
  ICheckInputSerialParamsRequest,
  ICheckInputTopupParams,
  IPackageAssigned,
  OrderRequest,
  OrderUpdate,
} from 'src/shared/interface/package_assigned';
import request from '../request';
import {
  ICheckInputListPhoneNumber,
  PackageUpdate,
} from 'src/shared/interface/register_and_cancel';

export const getAllRegisterPackage = async (params: any) => {
  const url = '/api/DataPackage/initiate/get-all';
  const res = await request<PaginationResponse<IPackageAssigned[]>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getAllCancelPackage = async (params: any) => {
  const url = '/api/DataPackage/cancel/get-all';
  const res = await request<PaginationResponse<IPackageAssigned[]>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkInputPhoneRegister = async (
  type: string,
  params: ICheckInputSerialParamsRequest[],
) => {
  const url = `/api/DataPackage/${type}/input`;
  const res = await request<ICheckInputListPhoneNumber>('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const checkInputPhoneTopup = async (params: ICheckInputTopupParams) => {
  const url = '/api/topup-provider/check';
  const res = await request<ICheckInputListPhoneNumber>('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const checkImportExcelRegisterPackage = async (
  type: string,
  params: ICheckImportExcelParamsRequest,
) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });
  const url = `/api/DataPackage/${type}/import`;
  const res = await request<ICheckInputListPhoneNumber>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const checkImportExcelTopup = async (params: ICheckImportExcelParamsRequest) => {
  const formData = new FormData();
  Object.keys(params).map((item) => {
    formData.append(`${item}`, params[`${item as keyof typeof params}`]);
  });
  const url = `/api/topup-provider/import`;
  const res = await request<ICheckInputListPhoneNumber>('post', url, formData);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const createRegisterPackage = async (type: string, params: any) => {
  const url = `/api/DataPackage/${type}/create-order`;
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const createTopupPackage = async (params: any) => {
  const url = '/api/topup-provider/create-order';
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updateTopupPackage = async (params: any) => {
  const url = '/api/topup-provider/edit-order';
  const res = await request('post', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getDetailPackage = async (type: string, id: string) => {
  const url = `/api/DataPackage/${type}/get-detail/${id}`;
  const res = await request<IPackageAssigned>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getDetailTopup = async (id: string) => {
  const url = `/api/topup-provider/get-detail/${id}`;
  const res = await request<IPackageAssigned>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};

export const registerAndCancelPackage = async (type: string, data: OrderRequest) => {
  const url = `/api/DataPackage/${type}/order`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const confirmTopupPackage = async (data: OrderRequest) => {
  const url = `/api/topup-provider/order`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const cancelTopupPackage = async (id: string) => {
  const url = `/api/topup-provider/cancel-order/${id}`;
  const res = await request<IPackageAssigned>('put', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const deleteRegisterPackage = async (orderId: string) => {
  const url = `/api/DataPackage/delete-order/${orderId}`;
  const res = await request<IPackageAssigned>('delete', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const deleteTopup = async (orderId: string) => {
  const url = `/api/topup-provider/delete-order/${orderId}`;
  const res = await request<IPackageAssigned>('delete', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getPackageAssigned = async (simType?: number) => {
  const url = `api/Provider/register-package/data-package/${simType}`;
  const res = await request<string[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getRegisterPackage = async (type?: string) => {
  const url = `/api/DataPackage?cosType=${type}`;
  const res = await request<string[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const updatePackage = async (type: string, data: PackageUpdate) => {
  const url = `/api/DataPackage/${type}/edit-order`;
  const res = await request<OrderUpdate>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const retryPackageAssigned = async (data: OrderUpdate) => {
  const url = `/api/Provider/retry-order`;
  const res = await request<IPackageAssigned>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const checkMbfCodePackageAssigned = async (data: CheckMbfCodePackageAssigned) => {
  const url = `/api/Provider/register-package/check-list-serial-sim`;
  const res = await request<ICheckInputListPhoneNumber>('post', url, data);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const exportTemplatePackageAssigned = async (type: number) => {
  const url = `/api/Provider/export-excel-template?type=${type}`;
  const res = await request<ICheckInputListSerial>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const cancelRegisterPackage = async (orderId: string) => {
  const url = `/api/DataPackage/cancel-order/` + orderId;
  const res = await request<ICheckInputListSerial>('post', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getRegisterAndCancelHistory = async (params: any) => {
  const url = `/api/DataPackage/history`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getTopupHistory = async (params: any) => {
  const url = `/api/topup-provider/get-history-topup`;
  const res = await request('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getTopupName = async () => {
  const url = `/api/topup-provider/discount?codeProvider=MOBI`;
  const res = await request<string[]>('get', url);
  if (res.isSucceeded) {
    return res.data;
  }
};
export const getAllTopup = async (params: any) => {
  const url = '/api/topup-provider/get-all';
  const res = await request<PaginationResponse<IPackageAssigned[]>>('get', url, params);
  if (res.isSucceeded) {
    return res.data;
  }
};
