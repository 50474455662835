import { createSlice } from '@reduxjs/toolkit';
// import { LoginForm, LoginResponse, RegisterDataRequest, RegisterUser, RequestError } from "../../types/auth";
import { PayloadAction } from '@reduxjs/toolkit';
import { InputProps, ModalProps } from 'antd';
import { ReactNode } from 'react';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';

export interface ModalState extends ModalProps {
  content?: ReactNode | JSX.Element;
  selectedHistory?: TransactionHistory;
  handleSubmit?: any;
  inputProps?: InputProps;
  navigate: string;
  selectedChangeSim?: string;
  pageAccess?: string;
}
const initialState: ModalState = {
  open: false,
  centered: false,
  closable: false,
  confirmLoading: false,
  keyboard: true,
  mask: true,
  maskClosable: true,
  title: undefined,
  width: 520,
  wrapClassName: undefined,
  zIndex: 1000,
  okButtonProps: undefined,
  navigate: '',
  pageAccess: ''
};
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalState(state, action: PayloadAction<Partial<ModalState>>) {
      Object.assign(state, action.payload);
    },
    resetModalState(state: any) {
      Object.keys(state).forEach((stateKey) => {
        Object.keys(initialState).forEach((initStateKey) => {
          if (stateKey === initStateKey) {
            state[stateKey] = { ...initialState }[initStateKey];
          }
        });
      });
    },
  },
});

export const { actions: modalAction, reducer: modalReducer } = modalSlice;
export const { setModalState, resetModalState } = modalSlice.actions;
