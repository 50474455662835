export enum SimStatusEnum {
  Success = 'Success',
  Failed = 'Failed',
  New = 'New',
  Paid = 'Paid',
  Processing = 'Processing',
  Canceled = 'Canceled',
  Pending = 'Pending',
}
export interface SimStatus {
  value: SimStatusEnum | null | string;
  label: string;
}

export const orderStatus: SimStatus[] = [
  {
    value: null,
    label: 'Tất cả',
  },
  {
    value: SimStatusEnum.Success,
    label: 'Success',
  },
  // {
  //   value: SimStatusEnum.Failed,
  //   label: 'Failed',
  // },
  {
    value: SimStatusEnum.New,
    label: 'New',
  },
  {
    value: SimStatusEnum.Paid,
    label: 'Paid',
  },
  {
    value: SimStatusEnum.Processing,
    label: 'Processing',
  },
  {
    value: SimStatusEnum.Canceled,
    label: 'Canceled',
  },
];
export const itemOrderStatus: SimStatus[] = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: SimStatusEnum.Success,
    label: 'Success',
  },
  {
    value: SimStatusEnum.Failed,
    label: 'Failed',
  },
  {
    value: SimStatusEnum.New,
    label: 'New',
  },
  {
    value: SimStatusEnum.Paid,
    label: 'Paid',
  },
  {
    value: SimStatusEnum.Processing,
    label: 'Processing',
  },
  {
    value: SimStatusEnum.Canceled,
    label: 'Canceled',
  },
];
export const findLable = (status?: string) => {
  if (!status) {
    return '';
  } else {
    return itemOrderStatus.find((order) => order.value === status)?.label;
  }
};

export enum ChangeSimStatus {
  New = 'New',
  Processing = 'Processing',
  Completed = 'Completed',
  Uncompleted = 'Uncompleted',
  Failed = 'Failed',
  Paid = 'Paid',
  PaymentFail = 'PaymentFail',
  Canceled = 'Canceled',
}

export enum SimType {
  PhysicalSim = 'PhysicalSim',
  Esim = 'Esim',
}
