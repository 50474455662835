import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getDetailBusinessProfile } from 'src/api/business_profile';
import { getAllLocation, getAllNationality } from 'src/api/location';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import { useAppSelector } from 'src/redux/hook';
import { REGEX_COMMON } from 'src/shared/common/regex';
import { cookCode, IDTypeStatus } from 'src/shared/common/userProfile';
import { IDTypeEnum, IDTypeNumberEnum, ProfileEnum } from 'src/shared/enum/user_profile';
import { ILocation } from 'src/shared/interface/location.interface';
import { ValueCode } from 'src/shared/interface/user_profile';
import { parseQueryString } from 'src/utils';

type Props = {
  statusUserProfile: any;
};

function UserInfo(props: Props) {
  const { statusUserProfile } = props;
  const form = Form.useFormInstance();
  const nationalityWatch = useWatch('nationality', form);
  const typeIdNumberWatch = useWatch('typeIdNumber', form);
  const [isVi, setIsVi] = useState<boolean>(false);
  useWatch('enterpriseName', form);
  useWatch('statusString', form);
  const { issuePlaceData } = useAppSelector((state) => state.userProfile);
  const { id } = parseQueryString(location.search);
  const [nationality, setNationality] = useState<ValueCode[]>([]);
  const [provinces, setProvinces] = useState<ValueCode[]>([]);
  const [district, setDistricts] = useState<ValueCode[]>([]);
  const [wards, setWards] = useState<ValueCode[]>([]);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      const res = await getDetailBusinessProfile();
      if (res) {
        if (res.account_profile_values) {
          res.account_profile_values.forEach(
            (element: { table_attribute_field_name: string; value: any }) => {
              if (element.table_attribute_field_name === 'enterpriseName') {
                form.setFieldsValue({
                  enterpriseName: element.value ? element.value : '',
                  enterpriseDistributorId: element.value
                    ? cookCode(res.id, 'DN') + ' - ' + element.value
                    : '',
                });
              }
            },
          );
        }
      }
    };
    fetchBusinessProfile();
  }, []);

  useEffect(() => {
    fetchLocation({ level: 1 }, 0);
    fetchNationality();
  }, []);

  useEffect(() => {
    setIsVi(form.getFieldValue('nationality')?.value === 'VNM');

    return () => {
      setIsVi(false);
    };
  }, []);

  useEffect(() => {
    if (isVi) {
      form.setFieldsValue({
        address: null,
        province: null,
        district: null,
        ward: null,
      });
    } else {
      form.setFieldsValue({
        address: '18 Nguyễn Văn Mại',
        province: 'Thành Phố Hồ Chí Minh',
        district: 'Quận Tân Bình',
        ward: 'Phường 04',
      });
    }
  }, [isVi]);

  const fetchNationality = async () => {
    const res = await getAllNationality();
    if (res) {
      const data = res.map((item: { code: any; name: any }) => {
        return { value: item.code, label: item.name };
      });
      setNationality(data);
    }
  };

  const fetchLocation = async (param: any, level: number): Promise<ILocation[] | undefined> => {
    const findLocation = param.parentId ? { parentId: param.parentId } : param;
    const res = await getAllLocation(findLocation);
    if (res) {
      const data: ValueCode[] = res.map((item) => {
        return {
          value: item.code.toString(),
          label: item.name,
          parentId: item.parentId,
          id: item.id,
        };
      });
      switch (level) {
        case 0:
          setProvinces(data);
          break;
        case 1:
          setDistricts(data);
          form.setFieldValue('district', { label: '', value: '' });
          form.setFieldValue('ward', { label: '', value: '' });
          break;
        case 2:
          setWards(data);
          form.setFieldValue('ward', { label: '', value: '' });
          break;

        default:
          break;
      }
    }
    return res;
  };

  const renderIssuePlace = (): ValueCode[] => {
    if (nationalityWatch ? nationalityWatch.value === 'VNM' : nationalityWatch === 'VNM') {
      switch (typeIdNumberWatch) {
        case IDTypeNumberEnum.PASSPORT:
          return [{ value: 'XNC', label: 'Cục Quản lý xuất nhập cảnh' }];
        case IDTypeNumberEnum.CMND:
          return issuePlaceData.filter(
            (item) => item.value !== 'CCC' && item.value !== 'CCD' && item.value !== 'XNC',
          );
        case IDTypeNumberEnum.CCCD:
          return [
            { value: 'CCC', label: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội' },
            {
              value: 'CCD',
              label: 'Cục Trưởng cục cảnh sát ĐKQL cư trú và DLQG về dân cư',
            },
          ];
        case IDTypeEnum.PASSPORT:
          return [{ value: 'XNC', label: 'Cục Quản lý xuất nhập cảnh' }];
        case IDTypeEnum.CMND:
          return issuePlaceData.filter(
            (item) => item.value !== 'CCC' && item.value !== 'CCD' && item.value !== 'XNC',
          );
        case IDTypeEnum.CCCD:
          return [
            { value: 'CCC', label: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội' },
            {
              value: 'CCD',
              label: 'Cục Trưởng cục cảnh sát ĐKQL cư trú và DLQG về dân cư',
            },
          ];
        default:
          break;
      }
    } else {
      return [];
    }
    return [];
  };

  const optionIDNumber = () => {
    switch (
      form.getFieldValue('nationality')?.value
        ? form.getFieldValue('nationality').value
        : form.getFieldValue('nationality')
    ) {
      case 'Viet Nam':
        return IDTypeStatus;
      case 'VNM':
        return IDTypeStatus;

      default:
        return [
          {
            value: '3',
            label: IDTypeEnum.PASSPORT,
          },
        ];
    }
  };

  return (
    <BoxWithLabel title='Thông tin người sử dụng' classNames='mt-4'>
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 12 }} label='ID cá nhân' name={'id'}>
            {form.getFieldValue('id')}
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Số lô' name={'lotId'}>
            {form.getFieldValue('lotId')}
          </Form.Item>
          <Form.Item
            labelCol={{ span: 12 }}
            label='ID hồ sơ DN'
            name={'enterpriseDistributorId'}
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              // defaultValue={JSON.parse(merchantInfo as string)?.merchantId ?? ''}
              // options={[
              //   {
              //     value: JSON.parse(merchantInfo as string)?.merchantId,
              //     label: JSON.parse(merchantInfo as string)?.merchantId,
              //   },
              // ]}
            ></Select>
          </Form.Item>
          <Form.Item labelCol={{ span: 12 }} label='Tên DN' name={'enterpriseName'}>
            {form.getFieldValue('enterpriseName') ? form.getFieldValue('enterpriseName') : ''}
          </Form.Item>
          <Form.Item
            label='Họ và tên'
            labelCol={{ span: 12 }}
            name='userName'
            rules={[
              { required: true, message: 'Trường này không được để trống' },
              {
                pattern: REGEX_COMMON.letterVietnamese,
                message: 'Tên không hợp lệ!',
              },
            ]}
          >
            <Input className='w-full' placeholder='Nhập họ và tên' maxLength={30} />
          </Form.Item>
          <Form.Item
            label='Ngày sinh'
            labelCol={{ span: 12 }}
            name='birthDay'
            className='col-span-6'
            rules={[
              { required: true, message: 'Trường này không được để trống' },
              () => ({
                validator(_, value) {
                  if (value) {
                    const fieldDate = dayjs(value);
                    const validateDate = dayjs(new Date()).subtract(18, 'years');
                    if (fieldDate.isAfter(validateDate)) {
                      return Promise.reject('Khách hàng chưa đủ 18 tuổi');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject('');
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={(current) => current && current >= moment().endOf('day')}
              format={'DD/MM/YYYY'}
              placeholder={'Chọn ngày sinh'}
              className='w-full'
            />
          </Form.Item>
          <Form.Item
            label='Giới tính'
            labelCol={{ span: 12 }}
            className='col-span-6'
            name='gender'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              options={[
                { value: '1', label: 'Nữ' },
                { value: '0', label: 'Nam' },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Số lượng TB active'
            labelCol={{ span: 12 }}
            className='col-span-6'
            name='countSubscriberActive'
          >
            {form.getFieldValue('isActive') === 'Active' ||
            (form.getFieldValue('isActive') === 'Inactive' &&
              form.getFieldValue('statusString') === ProfileEnum.Approved)
              ? form.getFieldValue('countSubscriberActive')
              : ''}
          </Form.Item>
          <div className='grid grid-cols-12 gap-4'>
            <Form.Item
              label='Trạng thái hoạt động'
              labelCol={{ span: 12 }}
              name='isActive'
              className='col-span-6'
            >
              {form.getFieldValue('isActive')}
            </Form.Item>
            <Form.Item
              label='Trạng thái hồ sơ'
              labelCol={{ span: 12 }}
              name='statusString'
              className='truncate col-span-6'
            >
              {form.getFieldValue('statusString')}
            </Form.Item>
          </div>
          {form.getFieldValue('statusString') === ProfileEnum.Rejected && (
            <div className=''>
              <span className='mr-4'>Lý do từ chối: </span>
              <span>{form.getFieldValue('note')}</span>
            </div>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            label='Quốc tịch'
            labelCol={{ span: 12 }}
            name='nationality'
            className='col-span-6'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              options={nationality}
              filterOption={(input, option) => {
                return (option!.label as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onChange={(_, valueCode: any) => {
                form.setFieldValue('nationality', {
                  label: valueCode.label,
                  value: valueCode.value,
                });
                if (valueCode.value !== 'VNM') {
                  form.setFieldValue('issuePlace', {
                    label: valueCode.label,
                    value: valueCode.value,
                  });
                  form.setFieldValue('typeIdNumber', '3');
                  setIsVi(false);
                } else {
                  form.setFieldValue('issuePlace', null);
                  setIsVi(true);
                }
                form.setFieldValue('idNumber', null);
              }}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Loại giấy tờ'
            labelCol={{ span: 12 }}
            name='typeIdNumber'
            className='truncate col-span-6 pb-4'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              placeholder='Vui lòng chọn'
              options={optionIDNumber()}
              onChange={() => {
                form.setFieldValue('issuePlace', null);
                form.setFieldValue('idNumber', null);
              }}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Số giấy tờ'
            labelCol={{ span: 12 }}
            name='idNumber'
            rules={[
              { required: true, message: 'Trường này không được để trống' },
              {
                pattern: REGEX_COMMON.numberAndLetter,
                message: 'Ký tự không hợp lệ!',
              },
              () => ({
                validator(_, value) {
                  if (value) {
                    const countNumber =
                      form.getFieldValue('typeIdNumber') === '1' ||
                      form.getFieldValue('typeIdNumber') === 'CMND'
                        ? 9
                        : // : form.getFieldValue('typeIdNumber') === '2' ||
                          //   form.getFieldValue('typeIdNumber') === 'CCCD'
                          // ? 12
                          // : 8;
                          12;
                    const field: boolean =
                      form.getFieldValue('nationality') && form.getFieldValue('typeIdNumber')
                        ? true
                        : false;
                    if (!field) {
                      return Promise.reject('Vui lòng chọn quốc tịch và loại giấy tờ!');
                    } else if (
                      value.length < countNumber &&
                      !(
                        form.getFieldValue('typeIdNumber') === '3' ||
                        form.getFieldValue('typeIdNumber') === 'Passport'
                      )
                    ) {
                      return Promise.reject('Vui lòng nhập đúng số giấy tờ!');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject('');
                  }
                },
              }),
            ]}
          >
            {typeIdNumberWatch === '3' ||
            (typeIdNumberWatch && typeIdNumberWatch.value === '3') ||
            typeIdNumberWatch === 'Passport' ? (
              <Input maxLength={9} type='text' />
            ) : (
              <Input
                onKeyDown={(evt) => {
                  if (!evt.ctrlKey && !evt.metaKey) {
                    if (
                      evt.key === 'e' ||
                      (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                      evt.key === '+' ||
                      evt.key === '.' ||
                      evt.key === '-' ||
                      Number(evt.key) > 9 ||
                      Number(evt.key) < 0
                    ) {
                      evt.preventDefault();
                    }
                  }
                }}
                onChange={(e) => {
                  const checkTypeID =
                    form.getFieldValue('typeIdNumber') === '1' ||
                    form.getFieldValue('typeIdNumber') === 'CMND'
                      ? 9
                      : form.getFieldValue('typeIdNumber') === '2' ||
                        form.getFieldValue('typeIdNumber') === 'CCCD'
                      ? 12
                      : 8;
                  if (e.target.value.length <= checkTypeID) {
                    form.setFieldValue('idNumber', e.target.value);
                  } else {
                    form.setFieldValue('idNumber', e.target.value.substring(0, checkTypeID));
                  }
                  e.preventDefault();
                }}
                type='number'
                maxLength={
                  form.getFieldValue('typeIdNumber') === '1' ||
                  form.getFieldValue('typeIdNumber') === 'CMND'
                    ? 9
                    : form.getFieldValue('typeIdNumber') === '2' ||
                      form.getFieldValue('typeIdNumber') === 'CCCD'
                    ? 12
                    : 8
                }
              />
            )}
          </Form.Item>
          <Form.Item
            label='Ngày cấp'
            labelCol={{ span: 12 }}
            name='issueDate'
            rules={[
              { required: true, message: 'Trường này không được để trống' },
              () => ({
                validator(_, value) {
                  if (value) {
                    const fieldDate = dayjs(value);
                    const birthDayDate = form.getFieldValue('birthDay')
                      ? dayjs(form.getFieldValue('birthDay')).add(14, 'years')
                      : '';
                    const expiredCMNDDate = dayjs().subtract(15, 'years');
                    const expiredPassportDate = dayjs().subtract(10, 'years');
                    if (birthDayDate && fieldDate.isBefore(birthDayDate)) {
                      return Promise.reject('Ngày cấp phải lớn hơn ngày sinh 14 năm!');
                    } else if (
                      (typeIdNumberWatch === '1' || typeIdNumberWatch === 'CMND') &&
                      fieldDate.isBefore(expiredCMNDDate)
                    ) {
                      return Promise.reject('CMND đã hết hạn!');
                    } else if (
                      (typeIdNumberWatch === '3' || typeIdNumberWatch === 'Passport') &&
                      fieldDate.isBefore(expiredPassportDate)
                    ) {
                      return Promise.reject('Passport đã hết hạn!');
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.reject('');
                  }
                },
              }),
            ]}
          >
            <DatePicker
              disabledDate={(current) => current && current >= moment().endOf('day')}
              format={'DD/MM/YYYY'}
              placeholder={'Từ'}
              className='w-full'
            />
          </Form.Item>
          <Form.Item
            label='Nơi cấp'
            labelCol={{ span: 12 }}
            name='issuePlace'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              filterOption={(input, option) => {
                return (option!.label as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onChange={(_, option) => {
                form.setFieldValue('issuePlace', option);
              }}
              options={renderIssuePlace()}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Địa chỉ thường trú'
            labelCol={{ span: 12 }}
            name='address'
            rules={[
              { required: true, message: 'Trường này không được để trống' },
              {
                pattern: REGEX_COMMON.letterVietnameseAddress,
                message: 'Địa chỉ không hợp lệ!',
              },
            ]}
          >
            <Input
              disabled={!isVi || (statusUserProfile === ProfileEnum.New && id)}
              className='w-full'
              placeholder='Nhập địa chỉ thường trú'
              maxLength={150}
            />
          </Form.Item>
          <Form.Item
            label='Tỉnh/ Thành phố'
            labelCol={{ span: 12 }}
            name='province'
            className='col-span-4'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              disabled={!isVi || (statusUserProfile === ProfileEnum.New && id)}
              filterOption={(input, option) => {
                return (option!.label as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onChange={(e, option) => {
                if (!isArray(option)) {
                  if (e) {
                    form.setFieldValue('province', option);
                    fetchLocation({ parentId: option.id }, 1);
                  }
                }
              }}
              options={provinces}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Quận/ Huyện'
            labelCol={{ span: 12 }}
            name='district'
            className='col-span-4'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              disabled={!isVi || (statusUserProfile === ProfileEnum.New && id)}
              filterOption={(input, option) => {
                return (option!.label as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={district}
              onChange={(e, option) => {
                if (!isArray(option)) {
                  if (e) {
                    form.setFieldValue('district', option);
                    fetchLocation({ parentId: option.id }, 2);
                  }
                }
              }}
            ></Select>
          </Form.Item>
          <Form.Item
            label='Phường/ Xã'
            labelCol={{ span: 12 }}
            name='ward'
            className='col-span-4'
            rules={[{ required: true, message: 'Trường này không được để trống' }]}
          >
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              disabled={!isVi || (statusUserProfile === ProfileEnum.New && id)}
              filterOption={(input, option) => {
                return (option!.label as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              options={wards}
              onChange={(_, option) => {
                if (!isArray(option)) {
                  form.setFieldValue('ward', option);
                }
              }}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
    </BoxWithLabel>
  );
}

export default UserInfo;
