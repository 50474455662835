import { RouteObject } from 'react-router-dom';
import DetailProfilePage from 'src/pages/management/businessProfile/page/DetailBusinessProfile';
import ChangeSim from 'src/pages/management/changeSim';
import CreateChangeSim from 'src/pages/management/changeSim/CreateChangeSim';
import BatchConnectionCreate from 'src/pages/management/connection/batch/list';
import HistoryConnection from 'src/pages/management/connection/history';
import BatchConnectionHistoryDetail from 'src/pages/management/connection/history/list';
import CreateOrderPage from 'src/pages/management/package_assigned/pages/CreateOrder';
import OrderListPage from 'src/pages/management/package_assigned/pages/OrderList';
import TransactionListPage from 'src/pages/management/transaction/pages/TransactionList';
import UserProfileDetailPage from 'src/pages/management/user_profile/page/create_user_profile';
import UserProfileBatchDetailPage from 'src/pages/management/user_profile/page/detail_user_profile_batch';
import UsersProfileBatchList from 'src/pages/management/user_profile/page/UserProfileBatchList';
import UsersProfile from 'src/pages/management/user_profile/page/UserProfileList';
import PrivateRoute from '../components/CPrivateRoute';
import HomePage from '../pages/home';
import ChangePasswordPage from '../pages/changePassword';
import Login from '../pages/login';
import ManagementPage from '../pages/management';
import BatchConnection from '../pages/management/connection/batch';
import SingleConnection from '../pages/management/connection/single';
import Dashboard from '../pages/management/dashboard';
import LocalPay from '../pages/management/localpay';
import ActiveSim from '../pages/management/activeSim/pages/list';
import ActiveSimDetailPage from '../pages/management/activeSim/pages/detail';
import EsimManagement from '../pages/management/esimManagement/pages/list';
import RegisterListPage from 'src/pages/management/register_and_cancel_package/pages/register/RegisterList';
import CreateRegisterPackagePage from 'src/pages/management/register_and_cancel_package/pages/register/CreateRegisterPackage';
import HistoryRegister from 'src/pages/management/register_and_cancel_package/pages/history';
import CancelListPage from 'src/pages/management/register_and_cancel_package/pages/cancel/CancelList';
import CreateCancelPackagePage from 'src/pages/management/register_and_cancel_package/pages/cancel/CreateCancelPackage';
import TopupListPage from 'src/pages/management/topup/pages/TopupList';
import CreateTopupPage from 'src/pages/management/topup/pages/CreateTopup';
import HistoryTopup from 'src/pages/management/topup/pages/history';

export const routes: RouteObject[] = [
  { path: 'login', element: <Login /> },
  {
    index: true,
    element: (
      <>
        <PrivateRoute>
          <HomePage />
        </PrivateRoute>
      </>
    ),
  },
  {
    path: 'home',
    element: (
      <>
        <PrivateRoute>
          <HomePage />
        </PrivateRoute>{' '}
      </>
    ),
  },
  {
    path: 'management',
    element: (
      <PrivateRoute>
        <ManagementPage />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
        index: true,
      },
      {
        path: 'settings',
        element: <Dashboard />,
      },
      {
        path: 'permissions',
        element: <Dashboard />,
      },
      {
        path: 'localpay',
        element: <LocalPay />,
      },
      {
        path: 'order-assigned-package',
        element: <OrderListPage />,
      },
      {
        path: 'order-assigned-package/create',
        element: <CreateOrderPage />,
      },
      {
        path: 'order-assigned-package/detail',
        element: <CreateOrderPage />,
      },
      {
        path: 'transaction',
        element: <TransactionListPage />,
      },
      {
        path: 'changeSim',
        element: <ChangeSim />,
      },
      {
        path: 'changeSim/createChangeSim',
        element: <CreateChangeSim />,
      },
      {
        path: 'connection/single',
        element: <SingleConnection />,
      },
      {
        path: 'connection/batch',
        element: <BatchConnection />,
      },
      {
        path: 'connection/batch/list',
        element: <BatchConnectionCreate />,
      },
      {
        path: 'connection/history',
        element: <HistoryConnection />,
      },
      {
        path: 'connection/history/list',
        element: <BatchConnectionHistoryDetail />,
      },
      {
        path: 'profile',
        element: <DetailProfilePage />,
      },
      {
        path: 'user-profile/list',
        element: <UsersProfile />,
      },
      {
        path: 'user-profile/create',
        element: <UserProfileDetailPage />,
      },
      {
        path: 'user-profile/detail',
        element: <UserProfileDetailPage />,
      },
      {
        path: 'user-profile/batch-create',
        element: <UsersProfileBatchList />,
      },
      {
        path: 'user-profile/batch-detail',
        element: <UserProfileBatchDetailPage />,
      },
      {
        path: 'active-sim/list',
        element: <ActiveSim />,
      },
      {
        path: 'active-sim/detail',
        element: <ActiveSimDetailPage />,
      },
      {
        path: 'esim-management',
        element: <EsimManagement />,
      },
      {
        path: 'register-and-cancel-package/register/list',
        element: <RegisterListPage />,
      },
      {
        path: 'register-and-cancel-package/register/history',
        element: <HistoryRegister />,
      },
      {
        path: 'register-and-cancel-package/register/create',
        element: <CreateRegisterPackagePage />,
      },
      {
        path: 'register-and-cancel-package/register/detail',
        element: <CreateRegisterPackagePage />,
      },
      {
        path: 'register-and-cancel-package/cancel/list',
        element: <CancelListPage />,
      },
      {
        path: 'register-and-cancel-package/cancel/create',
        element: <CreateCancelPackagePage />,
      },
      {
        path: 'register-and-cancel-package/cancel/detail',
        element: <CreateCancelPackagePage />,
      },
      {
        path: 'topup/list',
        element: <TopupListPage />,
      },
      {
        path: 'topup/create',
        element: <CreateTopupPage />,
      },
      {
        path: 'topup/detail',
        element: <CreateTopupPage />,
      },
      {
        path: 'topup/history',
        element: <HistoryTopup />,
      },
    ],
  },
  {
    path: 'change-password',
    element: (
      <>
        <PrivateRoute>
          <ChangePasswordPage />
        </PrivateRoute>{' '}
      </>
    ),
  },
];
