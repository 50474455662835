import { Button, Col, message, ModalProps, Radio, Row } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { paymentPackageAssigned } from 'src/api/package_assigned';
import { confirmTopupPackage, getDetailTopup } from 'src/api/register_and_cancel_package';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import Loading from 'src/components/CLoading';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { resetModalState, setModalState } from 'src/redux/slice/modal.store';
import { TransactionHistoryType } from 'src/shared/enum/localpay';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import { TransactionHistory } from 'src/shared/interface/localpay.interface';
import { OrderRequest } from 'src/shared/interface/package_assigned';
import { formatDateTime, formatMoney } from 'src/utils';
interface ModalTopUpProps extends ModalProps {
  selectedHistory?: TransactionHistory;
}

function TopupPackageBillingLocalPayModal({ selectedHistory }: ModalTopUpProps) {
  const dispatch = useAppDispatch();
  const { order, refresh, balance } = useAppSelector((state) => state.global);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [dataDetailLine, setDataDetailLine] = useState<
    {
      topupName: string;
      toTalAmount: number;
      countItem: number;
    }[]
  >([]);

  async function handlePayBill() {
    setIsModalLoading(true);
    setOpenModalConfirm(false);
    if (merchantInfo) {
      const data: OrderRequest = {
        accountNumber: JSON.parse(merchantInfo).payAccountId ?? '',
        orderId: order?.id ? order?.id : '',
        type: 16,
        distibutorId: merchantInfo ? JSON.parse(merchantInfo).merchantId : '',
        description: '',
        updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
      };
      try {
        // thanh toan don hang nap tien
        const res = await paymentPackageAssigned(data);
        if (res) {
          await confirmTopupPackage({ orderId: order?.id });

          dispatch(setGlobalState({ refresh: !refresh }));
          dispatch(setModalState({ open: false }));
          message.success('Thanh toán thành công');
        }
      } catch (error) {
        console.log(error);
        setIsModalLoading(false);
      }
    }
    dispatch(setGlobalState({ refresh: !refresh }));
  }

  const fetchDetailOrder = async (id: string) => {
    const res = await getDetailTopup(id);
    if (res) {
      dispatch(setGlobalState({ order: res }));
    }
  };

  useEffect(() => {
    if (selectedHistory && selectedHistory.paymentId) {
      fetchDetailOrder(selectedHistory.paymentId);
    }
  }, [selectedHistory]);

  useEffect(() => {
    if (order) {
      const listDataDetail: {
        topupName: string;
        toTalAmount: number;
        countItem: number;
      }[] = [];
      order?.overFlowOrderDetailResponses?.forEach((item: any) => {
        const findIndexTopup = listDataDetail.findIndex(
          (predicate) => predicate.topupName === item?.topupName,
        );
        if (findIndexTopup === -1) {
          listDataDetail.push({
            topupName: item.topupName,
            toTalAmount: item.price,
            countItem: 1,
          });
        } else {
          listDataDetail[findIndexTopup] = {
            ...listDataDetail[findIndexTopup],
            countItem: listDataDetail[findIndexTopup].countItem + 1,
            toTalAmount: listDataDetail[findIndexTopup].toTalAmount + item.price,
          };
        }
      });

      setDataDetailLine(listDataDetail);
    }
  }, [order]);

  return (
    <Fragment>
      {isModalLoading && <Loading />}
      <div className='flex justify-end'>
        <button
          onClick={() => dispatch(resetModalState())}
          className=' text-3xl cursor-pointer border-transparent bg-transparent'
        >
          <RxCross2 />
        </button>
      </div>
      <BoxWithLabel title='Thông tin thanh toán' classNames='mb-[1.5rem]'>
        <Row gutter={[12, 0]} className='mb-[1rem]'>
          <Col span='12'>
            <Row>
              <Col span='8'>Mã giao dịch</Col>
              <Col span='16'>{order?.transactionId}</Col>
              <Col span='8'>Ngày:</Col>
              <Col span='16'>
                {order?.transactionDate && new Date(order?.transactionDate).getFullYear() > 1900
                  ? formatDateTime(order.transactionDate)
                  : formatDateTime(new Date())}
              </Col>
              <Col span='8'>Diễn giải:</Col>
              <Col span='16'>{'Trừ tiền nạp tiền điện thoại'}</Col>
              {/* <Col span='8'>Mã hồ sơ DN:</Col>
              <Col span='16'>{order?.id ? order.id.split('-')[0] : ''}</Col>

              <Col span='8'>Tên DN:</Col>
              <Col span='16'>{order?.enterpriseName ? order.enterpriseName : ''}</Col> */}
            </Row>
          </Col>
          <Col span='12'>
            <Row>
              <Col span='8'>Tình trạng</Col>
              <Col span='16'>{order?.transactionStatusString}</Col>
              <Col span='8'>Người thanh toán:</Col>
              <Col span='16'>{merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}</Col>
              <Col span='8'>Đơn hàng tham chiếu:</Col>
              <Col span='16'>{order?.id}</Col>
            </Row>
          </Col>
        </Row>
        <p className='font-bold mb-[0.5rem]'>Chi tiết hóa đơn:</p>
        <div className='normal-table max-h-96 overflow-y-auto'>
          <table width='100%' className='normal-table'>
            <thead>
              <tr>
                <th>STT</th>
                <th>Số lượng</th>
                <th>Mệnh giá</th>
                <th>Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              {dataDetailLine.map((predicate, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{predicate.countItem ?? 1}</td>
                    <td>{predicate?.topupName ? predicate?.topupName + 'đ' : ''}</td>
                    <td>
                      {predicate?.toTalAmount ? formatMoney(predicate?.toTalAmount, 'đ') : '-'}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>Tổng cộng</td>
                <td>{order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BoxWithLabel>
      <BoxWithLabel title='Hình thức thanh toán' classNames='mb-[1.5rem]'>
        <Radio checked className='mb-[0.5rem]'>
          Localpay
        </Radio>
        <Row gutter={8}>
          <Col span='6'>LocalPay ID:</Col>
          <Col span='6'>{merchantInfo ? JSON.parse(merchantInfo).payAccountId : '-'}</Col>
          <Col span='6'>Tên chủ tài khoản:</Col>
          <Col span='6'>{merchantInfo ? JSON.parse(merchantInfo).merchantName : '-'}</Col>
          {/* <Col span='4'>Số dư:</Col>
          <Col span='4'>{balance ? formatMoney(balance, 'đ') : '-'}</Col> */}
        </Row>
      </BoxWithLabel>
      {[
        TransactionHistoryType.Topup,
        TransactionHistoryType.Recharge,
        TransactionHistoryType.RechargeAsimPortal,
      ].includes(order?.type as TransactionHistoryType) ? (
        <Row className='my-4'>
          <Col span={12}>
            <Row gutter={[0, 12]} className='w-full'>
              <Col span={8}>Người tạo</Col>
              <Col span={16}>{order?.createdBy}</Col>
            </Row>
          </Col>
          <Col span={12}>
            {' '}
            <Row gutter={[0, 12]} className='w-full'>
              <Col span={8}>Ngày tạo</Col>
              <Col span={16}>{order?.createdAt ? formatDateTime(order?.createdAt) : '-'}</Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className='my-4 border-solid border px-4'>
          <Col span={24}>
            <Row gutter={[0, 12]} className='w-full'>
              <Col span={8}>Người tạo</Col>
              <Col span={16}>{order?.createdBy}</Col>
            </Row>
          </Col>
          <Col span={24}>
            {' '}
            <Row gutter={[0, 12]} className='w-full'>
              <Col span={8}>Ngày tạo</Col>
              <Col span={16}>{order?.createdAt ? formatDateTime(order?.createdAt) : '-'}</Col>
            </Row>
          </Col>
        </Row>
      )}
      {(order?.statusString === OrderStatusEnum.PAYMENT_FAIL ||
        order?.statusString === OrderStatusEnum.New) && (
        <div className='row-button justify-end'>
          <Button
            onClick={() => setOpenModalConfirm(true)}
            disabled={
              order?.statusString !== OrderStatusEnum.PAYMENT_FAIL &&
              order?.statusString !== OrderStatusEnum.New
            }
          >
            Thanh toán
          </Button>
        </div>
      )}
      <ModalConfirm
        open={openModalConfirm}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xác nhận thanh toán?</div>
            <p>
              Bạn có chắc chắn muốn thanh toán{' '}
              {order?.toTalAmount ? formatMoney(order?.toTalAmount, 'đ') : '-'} cho{' '}
              {order?.countSerial ?? 0} STB thực hiện nạp tiền?
            </p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handlePayBill} className='h-full w-full' disabled={isModalLoading}>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirm(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

export default TopupPackageBillingLocalPayModal;
