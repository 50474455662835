import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import { getTopupHistory, getTopupName } from 'src/api/register_and_cancel_package';
import TableContent from 'src/components/CTable';
import { useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { SimStatusEnum } from 'src/shared/enum/change_sim';
import { TransactionHistoryStatus } from 'src/shared/enum/localpay';
import { IConnectionBatch } from 'src/shared/interface/connection';
import { IOrderStatus, IPackageAssigned } from 'src/shared/interface/package_assigned';
import {
  exportExcel,
  formatDate,
  formatMoney,
  formatPhoneNumberV2,
  parseQueryString,
} from 'src/utils';
import { requestTypeToString } from 'src/utils/register_cancel';

const HistoryTopup = () => {
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const [sortColumn] = useState<ISortColumn>();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const params = location.search;
  const dispatch = useDispatch();
  const { openTabs } = useAppSelector((state) => state.global);
  const navigate = useNavigate();

  const [data, setData] = useState<PaginationResponse<[IConnectionBatch]>>();
  const [dataTopupName, setDataTopupName] = useState<IOrderStatus[]>([]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current >= moment().endOf('day');
  };

  const handleFormSearch = (value: any) => {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : value.dateFrom ?? undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : value.dateTo ?? undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });

    setSearchParams({ ...searchParams, ...values });
  };

  const fetchListConnectionHistory = async () => {
    const params = parseQueryString(location.search);
    const res = await getTopupHistory({
      ...params,
      StatusString: params.StatusString === '-1' ? null : params.StatusString,
    });
    if (res) {
      setData(res);
    }
  };

  const fetchGetTopupName = async () => {
    await getTopupName().then((res: any) => {
      if (res) {
        const dataPackage: IOrderStatus[] = [{ label: 'Tất cả', value: '' }];
        res?.map((item: any) => {
          dataPackage?.push({
            label: item.name,
            value: item.name,
          });
        });
        setDataTopupName(dataPackage);
      }
    });
  };
  useEffect(() => {
    fetchGetTopupName();
  }, []);

  const handleDetailTopup = (order: any) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'topup/detail' + '?id=' + order.orderId,
      }),
    );
    if (!openTabs.find((tab) => tab.key === 'topup/detail' + '?id=' + order.orderId)) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem chi tiết Nạp tiền ' + order.orderId,
              children: '',
              key: 'topup/detail' + '?id=' + order.orderId,
              closable: true,
            },
          ],
        }),
      );
    }
    navigate(`/management/topup/detail?id=${order.orderId}`);
  };

  useEffect(() => {
    fetchListConnectionHistory();
  }, [location.search]);

  const handleExport = () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `lich_su_nap_tien_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    const url = process.env.REACT_APP_API_URL + '/api/topup-provider/export-history-topup' + params;
    exportExcel(url).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Mã đơn',
      fieldName: 'orderId',
      renderSpecialCard: (record) => {
        return (
          <button
            className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
            onClick={() => handleDetailTopup(record)}
          >
            {record.orderId ?? ''}
          </button>
        );
      },
      isHideSort: true,
    },
    {
      name: 'Loại',
      fieldName: 'type',
      renderSpecialCard: (record) => {
        return <span>{record.type && requestTypeToString(record.type)}</span>;
      },
      isHideSort: true,
    },
    {
      name: 'STB',
      fieldName: 'phoneNumber',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{formatPhoneNumberV2(record?.phoneNumber)}</span>;
      },
    },
    {
      name: 'Mệnh giá',
      fieldName: 'topupName',
      isHideSort: true,
      renderSpecialCard: (record: IPackageAssigned) => {
        return <span>{record.price ? record.topupName + 'đ' : 0}</span>;
      },
    },
    {
      name: 'Thành tiền',
      fieldName: 'price',
      isHideSort: true,
      renderSpecialCard: (record: IPackageAssigned) => {
        return <span>{record.price ? formatMoney(record.price, 'đ') : 0}</span>;
      },
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Mô tả lỗi', fieldName: 'errorMessage', isHideSort: true },
    { name: 'Người tạo', fieldName: 'createdBy', isHideSort: false },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record) => {
        return <span>{record.createdAt ? formatDate(record.createdAt) : ''}</span>;
      },
    },
    { name: 'Người cập nhật cuối', fieldName: 'updatedBy' },
    {
      name: 'Ngày cập nhật cuối',
      fieldName: 'updatedAt',
      renderSpecialCard: (record) => {
        return <span>{record.updatedAt ? formatDate(record.updatedAt) : ''}</span>;
      },
    },
  ];

  const listStatus = [
    { name: 'Tất cả', value: '' },
    { name: 'Pending', value: TransactionHistoryStatus.Pending },
    { name: 'Success', value: TransactionHistoryStatus.Success },
    { name: 'Failed', value: TransactionHistoryStatus.Failed },
    { name: 'Canceled', value: TransactionHistoryStatus.Canceled },
    { name: 'Error', value: TransactionHistoryStatus.Error },
    { name: 'New', value: TransactionHistoryStatus.NEW },
    { name: 'Paid', value: TransactionHistoryStatus.PAID },
    { name: 'Processing', value: TransactionHistoryStatus.Processing },
  ];

  const renderOptions = (listOpts: any[]) => {
    return listOpts.map((item) => {
      return (
        <Select.Option value={item.value} key={item.value}>
          {item.name}
        </Select.Option>
      );
    });
  };

  return (
    <div>
      <Form
        onFinish={handleFormSearch}
        layout='inline'
        className='gap-2'
        initialValues={{
          topupName: '',
          StatusString: '',
        }}
      >
        <Form.Item name='search'>
          <Input
            prefix={<SearchOutlined />}
            placeholder='Mã đơn, Số thuê bao'
            className='w-[400px]'
            allowClear
          />
        </Form.Item>
        <Form.Item name='topupName' label='Mệnh giá'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            options={dataTopupName}
          ></Select>
        </Form.Item>
        <Form.Item name='StatusString' label='Trạng thái'>
          <Select
            showSearch
            className='min-w-[150px]'
            placeholder='Vui lòng chọn'
            options={[
              {
                value: '',
                label: 'Tất cả',
              },
              {
                value: SimStatusEnum.Success,
                label: 'Success',
              },
              {
                value: SimStatusEnum.Failed,
                label: 'Failed',
              },
              {
                value: SimStatusEnum.New,
                label: 'New',
              },
              {
                value: SimStatusEnum.Paid,
                label: 'Paid',
              },
              {
                value: SimStatusEnum.Processing,
                label: 'Processing',
              },
              {
                value: SimStatusEnum.Canceled,
                label: 'Canceled',
              },
              {
                value: SimStatusEnum.Pending,
                label: 'Pending',
              },
            ]}
          >
            {/* {renderOptions(listStatus)} */}
          </Select>
        </Form.Item>
        <Form.Item name='' label='Người tạo'>
          <Select
            showSearch
            className='min-w-[150px]'
            defaultValue={merchantInfo ? JSON.parse(merchantInfo).merchantName : ''}
          >
            {/* <Select.Option value='merchantName'>Tất cả</Select.Option> */}
            <Select.Option value='merchantName'>
              {merchantInfo ? JSON.parse(merchantInfo).merchantName : ''}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='dateRange' label='Ngày tạo'>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            placeholder={['Từ ngày', 'đến ngày']}
            format={['DD/MM/YYYY', 'DD/MM/YYYY']}
          />
        </Form.Item>
        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>
      <div className='mt-6'>
        <TableContent
          bordered
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: 'Không tìm thấy kết quả' }}
          dataSource={data?.results ?? []}
          columns={columns}
          totalRecord={data?.rowCount ?? 0}
          sortColumn={sortColumn}
          onSort={(sort: ISortColumn) => {
            const pagination = parseQueryString(location.search);
            handleFormSearch({
              ...pagination,
              pageIndex: pagination.pageIndex ?? 1,
              pageSize: pagination.pageSize ?? 10,
              sortField: sort.fieldName,
              asc:
                sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
            });
          }}
        />
      </div>
    </div>
  );
};

export default HistoryTopup;
